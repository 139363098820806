<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px">
    <v-row no-gutters class="mx-2" v-if="isLoaded">
      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" align="center" no-gutters>
          <!-- Btn compute distances -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="computeDistanceSteps"
                :loading="isLoading.btnComputeDistanceSteps"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-satellite</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("studies.buttons.refreshDistanceSteps") }}</span>
          </v-tooltip>

          <!-- Refresh Grades btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="refreshMaterialGrades"
                :loading="isLoading.btnRefreshGrades"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>fas fa-star</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("studies.buttons.computeGrades") }}</span>
          </v-tooltip>

          <!-- Acv scope -->
          <v-chip class="mx-2">{{ acvScope }}</v-chip>

          <!-- Completion Percent of study -->
          <ProgressPercentage
            class="mx-2"
            :value="impact.completionPercentage * 100"
          />

          <!-- Edit btn -->
          <v-btn icon color="primary" @click="setShowEditDialog(true)">
            <v-icon small>fas fa-pencil</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <!-- Details -->
      <v-col cols="12">
        <v-row no-gutters v-if="impact">
          <!--- Grades -->
          <v-col cols="12" class="my-2">
            <ProductACVGrades :grades="impact.grades" />
          </v-col>

          <!-- Items -->
          <v-col cols="12" class="my-2">
            <ProductACVNumbers :impact="impact" />
          </v-col>

          <!-- Texts -->
          <v-col cols="12" class="my-2">
            <ProductACVTexts :impact="impact" :study="study" />
          </v-col>
        </v-row>
        <v-row v-else no-gutters>
          <p>{{ $t("studies.acv.no-data") }}</p>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-2" v-else>
      <Skeleton :occurrence="1" type="card" />
    </v-row>

    <!-- Edit dialog -->
    <ProductACVDialog
      v-if="showDialog.edit && impact"
      :value="showDialog.edit"
      :study="study"
      :item="impact"
      @close="(needRefresh) => setShowEditDialog(false, needRefresh)"
    />
  </v-card>
</template>

<script>
export default {
  name: "ProductACVTab",

  components: {
    ProductACVDialog: () => import("@/components/Studies/ACV/ProductACVDialog"),
    ProductACVNumbers: () =>
      import("@/components/Studies/ACV/ProductACVNumbers"),
    ProductACVTexts: () => import("@/components/Studies/ACV/ProductACVTexts"),
    ProductACVGrades: () => import("@/components/Studies/ACV/ProductACVGrades"),
    ProgressPercentage: () => import("@/components/Common/ProgressPercentage"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
  },
  mixins: [],

  props: {
    product: { type: Object, default: () => null },
    study: { type: Object, default: () => null },
  },

  data() {
    return {
      showDialog: {
        edit: false,
      },
      impact: null,
      isLoaded: false,
      isLoading: {
        btnRefreshGrades: false,
        btnComputeDistanceSteps: false,
      },
    };
  },

  created() {
    this.getImpact();
  },

  methods: {
    setShowEditDialog(val, needRefresh = false) {
      if (needRefresh) this.getImpact();
      this.showDialog.edit = val;
    },
    getImpact() {
      this.isLoaded = false;
      this.$http
        .get(`/studies/${this.study.id}/acv`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.impact = res.data;
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },

    refreshMaterialGrades() {
      this.isLoading.btnRefreshGrades = true;

      this.$http
        .patch(
          `/studies/${this.study.id}/refresh-grades`,
          {},
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.getImpact();
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoading.btnRefreshGrades = false;
        });
    },

    computeDistanceSteps() {
      this.isLoading.btnComputeDistanceSteps = true;

      this.$http
        .patch(
          `/studies/${this.$route.params.id}/compute-distance-steps`,
          {},
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoading.btnComputeDistanceSteps = false;
        });
    },
  },

  watch: {
    study(newVal, oldVal) {
      if (newVal) this.getImpact();
    },
  },

  computed: {
    acvScope() {
      const a = this.$t("constants.acvScopes.list").find(
        (el) => el.value === this.impact.acvScope
      );
      return a ? a.text : this.$t("common.misc.NA");
    },
  },
};
</script>

<style scoped></style>
